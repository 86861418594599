class APIModel {
  //for server
  // static URL = "https://backend.easy-card.co/";
  // static HOST = "https://backend.easy-card.co/api/";
  // static PORTAL = "easy-card.co";
  // static PORTAL_URL = "https://easy-card.co/";

  // for testing
  static URL = "http://backend.myeasycard.co/";
  static HOST = "http://backend.myeasycard.co/api/";
  static PORTAL = "myeasycard.co";
  static PORTAL_URL = "http://myeasycard.co/";
}

export default APIModel;
